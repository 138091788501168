import ReactDOM from 'react-dom';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, BrowserRouter, Redirect, withRouter } from 'react-router-dom';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { uuidv4 } from './utils/functions';
import i18next from './config/i18next';
import { USER_LOGIN, USER_LOGOUT } from './reducers/auth';
import './config/i18next';
import Axios from 'axios';
import * as serviceWorker from './serviceWorker';
import App from './App';

// const ErrorBoundary = React.lazy(() => import('./pages/ErrorBoundary'));

declare global {
    interface Window {
        $: any;
    }
}

const Navbar = React.lazy(() => import('./components/Navbar'));
const Footer = React.lazy(() => import('./components/Footer'));
const Alert = React.lazy(() => import('./components/Alert'));
const FixedIcons = React.lazy(() => import('./components/FixedIcons'));


const store = createStore(reducers, applyMiddleware(thunk));

let token = localStorage.getItem("token");
let userString = localStorage.getItem("user");
let expireString = localStorage.getItem("expire");
let localToken = localStorage.getItem("localToken");

if (window.location.pathname.startsWith("/en")) {
    i18next.changeLanguage('en');
} else if (window.location.pathname.startsWith("/ru")) {
    i18next.changeLanguage("ru")
} else if (window.location.pathname.startsWith("/uz")) {
    i18next.changeLanguage("uz")
} else if (window.location.pathname == "/") {
    i18next.changeLanguage("ru")
}

if (!localToken) {
    localToken = uuidv4();

    localStorage.setItem("localToken", localToken);
}

if (token && userString) {
    let canAuth = true;

    if (expireString) {
        let expire = Date.parse(expireString);
        let now = Date.parse(new Date().toLocaleString());

        if (expire < now) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('expire');
            store.dispatch({
                type: USER_LOGOUT,
                user: JSON.parse(userString)
            });

            canAuth = false;
        }
    }

    if (canAuth) {
        Axios.defaults.headers.common['Authorization'] = "Bearer " + token;

        store.dispatch({
            type: USER_LOGIN,
            user: JSON.parse(userString)
        });
    }

}

Axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response?.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');

        // history.push("/#!/login");

        store.dispatch({
            type: USER_LOGOUT,
            user: null
        });
    } else {
        return Promise.reject(error);
    }
});

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={i18next.language}>
            {/* <HashRouter hashType="hashbang"> */}

            <div>
                <ToastContainer
                    position="top-right"
                    autoClose={8000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    draggable
                    pauseOnHover
                />
                <React.Suspense fallback={<div></div>}>
                    <Route path="/" render={(props: any) => <Navbar  {...props} />} />
                    <Alert />
                    <FixedIcons />
                </React.Suspense>
                <App/>
                <React.Suspense fallback={<div></div>}>
                    <Footer />
                </React.Suspense>

            </div>

            {/* </HashRouter> */}
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();