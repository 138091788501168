let initPrevState: any = [];

export const FILL_COUNTRY = "FILL_COUNTRY";

export function country(prevState: any = initPrevState, action: any) {
    if (action.type === FILL_COUNTRY) {
        
        return [
            ...action.payload
        ];
    } 
    return prevState;
}
