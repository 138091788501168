let initPrevState: any = null;

export const FILL = "FILL";

export function setting(prevState: any = initPrevState, action: any) {
    if (action.type === FILL) {
        return {
            ...action.payload
        };
    } 
    return prevState;
}
