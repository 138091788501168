import {combineReducers} from 'redux';
import {auth} from './auth';
import {modal} from './modal';
import {alert} from './alert';
import {setting} from './setting';
import {country} from './country';
import {service} from './service';

export default combineReducers({
    auth,
    modal,
    alert,
    country,
    service,
    setting
});