let initPrevState:any = {
    show: false,
    text: ""
};

export const SHOW_MODAL = "SHOW_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export function modal (prevState:any = initPrevState, action:any){
    if (action.type === SHOW_MODAL){
        return {
            show: true,
            text: action.text
        };
    } else if (action.type === CLOSE_MODAL){
        return initPrevState;
    }

    return prevState;
}