let initPrevState: any = [];

export const ADD_ALERT = "ADD_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export function alert(prevState: any = initPrevState, action: any) {
    if (action.type === ADD_ALERT) {
        return [...prevState, action.alert];
    } else if (action.type === REMOVE_ALERT) {
        let newState = [...prevState];

        setTimeout(() => {
            newState.filter((a: any, i: number) => {
                return i != action.index;
            });
        }, 1000);
        
        return newState;
    }

    return prevState;
}