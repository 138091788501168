import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    ru: {
        translation: {
            "Еще от этого компании": "Еще от этой компании",
            "Агент": "Торговый агент",
            "Рекомендуемое разширение изображения": "Рекомендуемое разрешение изображения",
            "Подраздел": "Подкатегория",
            "This username has already been taken.": "Этот логин уже был занят.",
            "Username or password is invalid": "Имя пользователя или пароль неверен",
            "Discover your supplier": "Найдите своего поставщика",
            "Equip.uz - Discover your supplier": "Equip.uz - Найдите своего поставщика",
        }
    },
    en: {
        translation: {
            "Категории": "Categories",
            "ВЫБОР МНОГИХ": "CHOICE OF MANY",
            "Отправьте реквизиты вашей компании": "Send your company details",
            "Наименования компании": "Company name",
            "Ф.И.О. руководителя": "Full name of the Director",
            "Наименования банка": "Bank name",
            "МФО банка": "Bank MFO",
            "Расчётный счёт": "Checking account",
            "ИНН предприятия": "Company tin",
            "Юридический адрес предприятия": "Legal address of the company",
            "Найдите качественные поставщики, производители, покупатели, оптовые продавцы, продукты, экспортеры, импортеры и торговые лидеры с нашего сайта международной торговли.": "Find quality suppliers, manufacturers, buyers, wholesalers, products, exporters, importers and trade leaders from our international trade site.",
            "Введите имя и фамилию": "Enter your first and last name",
            "Применить": "Accept",
            "Бесплатно, входит в ваш тариф": "Free, included in your tariff",
            "Услуги": "Services",
            "Для поставщиков": "For suppliers",
            "Для покупателей": "For buyers",
            "Размещение рекламы": "Advertising",
            "Центр поддержки": "Support Center",
            "Мой кабинет": "My cabinet",
            "Найти": "Search",
            "Equip.uz - Discover your supplier": "Equip.uz - Discover your supplier",
            "Найти продукт или поставщика": "Search product or supplier",
            "Продукт": "Product",
            "Поставщик": "Supplier",
            "Переписки": "Chat",
            "Избранные": "Favorites",
            "Заявки": "Requests",
            "ПОПУЛЯРНЫЕ КАТЕГОРИИ": "POPULAR CATEGORIES",
            "Все категории": "All categories",
            "РЕКОМЕНДУЕМЫЕ ТОВАРЫ": "RECOMMENDED PRODUCTS",
            "Рекомендуемые товары": "Recommended products",
            "АКТИВНЫЕ КОМПАНИИ": "ACTIVE COMPANIES",
            "НАШИ КЛИЕНТЫ": "OUR CLIENTS",
            "БИЗНЕС ИДЕИ": "BUSINESS IDEAS",
            "Бизнес идеи": "Business ideas",
            "ВЫСТАВКИ": "EXHIBITIONS",
            "ТЕХНОЛОГИ": "TECHNOLOGISTS",
            "НОВОСТИ": "NEWS",
            "БЫСТРЫЙ ПОИСК ОБОРУДОВАНИЙ": "FAST SEARCH PRODUCTS",
            "О нас": "About us",
            "Обслуживание клиентов": "Customer service",
            "Полезная информация": "Helpful information",
            "Наши партнеры": "Our partners",
            "Следите за нами": "Follow us",
            "Все правы защищены": "All rights reserved",
            "ООО": "Ltd",
            "ВСЕ КАТЕГОРИИ": "ALL CATEGORIES",
            "все категории": "all categories",
            "ТОВАРЫ, КОТОРЫЕ ВЫ СМОТРЕЛИ": "PRODUCTS, WHICH YOU WATCHED",
            "товаров": "products",
            "Сортировка": "Sorting",
            "Название": "Name",
            "Дата": "Date",
            "Просмотр": "Views",
            "Популярность": "Popularity",
            "Не нашли товар": "Did not find the product",
            "Оставьте заявку": "Leave a request",
            "Создать заявку": "Create request",
            "Создать ЗАЯВКУ": "Create request",
            "Написать": "Write",
            "Сравнить": "Compare",
            "Страна": "Country",
            "Сайт": "Site",
            "Фильтр товаров": "Product filter",
            "Состояние": "State",
            "Цена": "Price",
            "Фильтр поставщиков": "Supplier filter",
            "Сертификаты": "Certificates",
            "Основной рынок": "Main market",
            "Все страны": "All countries",
            "Тип": "Type",
            "Очистить": "Clear",
            "В сравнении": "In comparison",
            "Контакты компании": "Company contacts",
            "О компании": "About company",
            "Продукты компании": "Company products",
            "Профиль компании": "Company profile",
            "Модель": "Model",
            "Категория": "Category",
            "Минимальный заказ": "Minimum order",
            "Производственная мощность": "Productive capacity",
            "Среднее время ответа": "Average response time",
            "Процент ответов": "Response rate",
            "Условия и способы оплаты": "Terms and payment methods",
            "Поделиться": "Share",
            "В избранные": "To favorites",
            "Удалить из избранных": "Remove from favorites",
            "Скачать предложение": "Download offer",
            "Категории компании": "Company categories",
            "Просмотреть профиль": "View profile",
            "Контакты": "Contacts",
            "Печатать страницу": "Print page",
            "Топ оборудования": "Top products",
            "Характеристики": "Specifications",
            "Описание": "Description",
            "Связаться с поставщиком": "Contact supplier",
            "Имя": "Name",
            "Компания": "Company",
            "Телефон": "Phone",
            "Cообщение": "Message",
            "Почта": "Email",
            "Выберите": "Select",
            "Рекомендовать похожих поставщиков, если этот поставщик не ответил в течении 24 часов.": "Recommend similar suppliers if this supplier has not responded within 24 hours.",
            "Напишите сюда ваше сообщение": "Write your message here",
            "Отправить": "Send",
            "Часто задаваемые вопросы": "Frequently asked Questions",
            "Отзывы клиентов": "Customer reviews",
            "Написать отзыв": "Write a review",
            "Еще от этого компании": "More from this company",
            "Подписка на товары этого поставщика": "Subscription for products from this supplier",
            "Подписаться": "Subscribe",
            "Введите электронную почту": "Enter your email",
            "Ф.И.О.": "Full name",
            "Оценка": "Rating",
            "Заголовок отзыва": "Review title",
            "Основные товары": "Main products",
            "Все товары компании": "Company's all products",
            "Найти товар": "Search product",
            "Год основания": "Year of foundation",
            "Сотрудники": "Staff",
            "Тип компании": "Company type",
            "Способы оплаты": "Payment methods",
            "Производитель": "Manufacturer",
            "Торговый агент": "Trading agent",
            "Агент": "Trading agent",
            "Основано в": "Founded in",
            "Все услуги": "All services",
            "Зарегистрироваться как поставщик": "Signup as supplier",
            "Зарегистрироваться как покупатель": "Signup as buyer",
            "Покупатель": "Buyer",
            "Переписки компании": "Company chat",
            "Статистика компании": "Company statistics",
            "Тариф компании": "Company tariff",
            "Заявки пользователей": "User requests",
            "Мои заказы услуг": "My service orders",
            "Мои заказы реклам": "My ad orders",
            "Ваш персональный менеджер": "Your personal manager",
            "Просмотров осталось": "Views remain",
            "Рекламировать свои продукты": "Advertise your products",
            "Товары": "Products",
            "Обратная связь": "Feedback",
            "Открыть и ответить": "Open and answer",
            "Открыть": "Open",
            "Мои переписки": "My chat",
            "Все": "All",
            "Переписка с": "Chatting with",
            "Введите сообщение": "Write message",
            "Вы": "You",
            "Город": "City",
            "Адрес": "Address",
            "Веб-сайт": "Website",
            "Подраздел": "Subcategory",
            "Укажите категорию": "Select category",
            "Баннер": "Banner",
            "Добавить фото": "Add photo",
            "Добавить сертификат": "Add certificate",
            "Галерея": "Gallery",
            "Логотип": "Logo",
            "Добавить категорию": "Add category",
            "Сохранить": "Save",
            "Выйти": "Logout",
            "Мои заявки": "My requests",
            "Мои избранные": "My favorites",
            "Мои подписки": "My subscribes",
            "Личные данные": "Personal data",
            "История": "History",
            "Просмотр товаров": "Visited products",
            "Сравнения": "Compare",
            "Ничего не найдено": "Nothing found",
            "Указанная почта": "Entered email",
            "Отменить": "Cancel",
            "№ заказа": "Order №",
            "Услуга": "Service",
            "Статус": "Status",
            "Ожидание оплаты": "Waiting for payment",
            "Оплачен": "Paid",
            "Отменён": "Canceled",
            "Удалить": "Remove",
            "сум": "sum",
            "Оплатить с помощью": "Pay with",
            "ОПЛАТИТЬ": "PAY",
            "Оплачен и выполняется": "Paid and active",
            "В модерации": "Moderating",
            "Логин": "Username",
            "Пароль": "Password",
            "Физическое лицо": "Individual",
            "Юридическое лицо": "Entity",
            "Имя и фамилия": "Full name",
            "рождения": "birthday",
            "Если хотите изменить": "If you want to change",
            "Действия": "Action",
            "Страна происхождения": "Country of origin",
            "Оплатить": "Pay",
            "Добавить продукты": "Add product",
            "Добавление нового товара": "Adding new product",
            "Валюта": "Currency",
            "Наличие или под заказ(дни)": "Current count or under the order (days)",
            "Производительность": "Productivity",
            "Добавить параметр": "Add specification",
            "Добавить товар": "Add product",
            "Ссылка на Ютуб": "Youtube link",
            "Не загружено": "Not uploaded",
            "Главная картинка": "Main picture",
            "Ссылка": "Link",
            "на Ютуб": "to YouTube",
            "Новый": "New",
            "Б/У": "Was in use",
            "договорная": "negotiable",
            "Количество": "Quantity",
            "просмотров": "views",
            "заявок": "requests",
            "переписок": "chats",
            "Приоритет": "Priority",
            "Количество товаров": "Product quantity",
            "-й": "th",
            " шт.": " pcs.",
            "Витрина товаров": "Product showcase",
            "Скидка на дополнительную рекламу": "Additional advertising discount",
            "Логотип компании": "Company logo",
            "Доступ к «Площадке заявок»": "Access to the “Application Platform”",
            "Реклама на главной странице": "Homepage advertising",
            " показов": " views",
            "Показать все": "Show all",
            " ключевых слов": " keywords",
            "SEO оптимизация": "SEO optimization",
            "Менеджер за заполнения контента": "Content Filling Manager",
            "Количество<br>просмотров": "Views",
            "Email рассылка к базе данных покупателей": "Email newsletter to customer database",
            "Возможность рекламы": "Advertising opportunity",
            "Уже подписаны": "Already subscribed",
            "Неограничено": "Not limited",
            " товаров": " products",
            "Добавить новый": "Add new",
            "Редактировать": "Edit",
            "Добавление часто задаваемого вопроса": "Adding a frequently asked question",
            "Вопрос": "Question",
            "Ответ": "Answer",
            "Добавить": "Add",
            "Действие выполнено успешно.": "Action completed successfully.",
            "Вы уверены?": "Are you sure?",
            "Подробно": "In detail",
            "Свернуть": "Collapse",
            "Откликов": "Feedbacks",
            "Результаты поиска": "Searching results",
            "Фильтр заявок по странам": "Filter applications by country",
            "Поиск по ключевому слову": "Search by keyword",
            "Новый пользователь": "New user",
            "Логин *": "Username *",
            "Пароль *": "Password *",
            "Запомнить меня": "Remember me",
            "Забыл пароль": "Forgot password",
            "Вход": "Sign in",
            "Войти": "Sign in",
            "или": "or",
            "часа": "h.",
            "Кем вы хотите": "Who do you want",
            "зарегистрироваться": "signup",
            "Покупатель товаров": "Product customer",
            "Поставщик товаров": "Product supplier",
            "Зарегистрироваться": "Sign up",
            "Редактирование часто задаваемого вопроса": "Editing a frequently asked question",
            "Зарегистрироваться как пользователь": "Signup as customer",
            "Регистрация": "Signup",
            "Вы должны принять условия": "You must accept the terms",
            "Что вас": "What are you",
            "интересует": "interested in",
            "Выберите подкатегорию": "Choose a subcategory",
            "Я согласен с": "I agree with",
            "условиями": "conditions",
            "Название компании": "Company name",
            "Реклама": "Advertising",
            "Главная страница сайта": "Main page of the site",
            "Страница продуктов": "Product page",
            "Заказать": "Order",
            "Условия": "Terms",
            "Условия рекламы": "Advertising terms",
            "Заявка на рекламу": "Application for advertising",
            "Период": "Period",
            "Сумма": "Amount",
            "Перетащите или": "Drag and drop or",
            "загрузите": "upload",
            "файл баннер": "banner file",
            "Рекомендуемое разширение изображения": "Recommended image resolution",
            "максимальный размер 2 MB, тип файла jpeg, png.": "maximum size 2 MB, file type jpeg, png.",
            "Неправильный формат": "Invalid format",
            "C": "From",
            "по": "to",
            "Discover your supplier": "Discover your supplier",
            "Загружите файл": "upload file",
            "Неверный размер изображения": "Invalid image size",
            "Мои продукты": "My products",
            "Наличие": "Availability",
            "Добавить новый продукт": "Add new product",
            "Фильтрация": "Filtration",
            "по рекламе": "by advertising",
            "Активирован": "Activated",
            "Деактивирован": "Deactivated",
            "В ожидании": "Pending",
            "Рекламировать": "To advertise",
            "Дата добавления": "Created date",
            "Дата редактирования": "Updated date",
            "Активный": "Active",
            "Значение": "Value",
            "Деактивный": "Inactive",
            "Добавить тип": "Add type",
            "Разрешено: jpg, jpeg, png": "Allowed: jpg, jpeg, png",
            "Необходимо заполнить описание": "Description is required",
            "Организатор": "Organizer",
            "Выставочный центр": "Exhibition Center",
            "Отправить запрос на участие данной выставки": "Send request for participation in this exhibition",
            "Отправить запрос на данную технологу": "Send a request for this technologist",
            "Укажите хотябы один способ оплаты": "Indicate at least one payment method",
            "Скачать типовой договор": "Download agreement",
            "Еженедельные товары": "Weekly products",
            "Январь": "January",
            "Февраль": "February",
            "Март": "March",
            "Апрель": "April",
            "Май": "May",
            "Июнь": "June",
            "Июль": "July",
            "Август": "August",
            "Сентябрь": "September",
            "Выставки": "Exhibitions",
            "Октрябрь": "October",
            "Декабрь": "December",
            "Новости": "News",
            "Не нашли нужную выставку? Отправить запрос": "Did not find the desired exhibition? Send request",
            "Не нашли нужную технологу? Отправить запрос": "Did not find the right technologist? Send request",
            "Читать подробно": "Read in detail",
            "Технологи": "Technologists",
            "года": "years old",
            "Наименование технологического направления": "Name of technological area",
            "День рождения": "Birthday",
            "У вас ограничение на количество товаров согласно вашему тарифу": "You have a limit on the quantity of products according to your tariff",
            "Чтобы добавить товар заполните профиль компании.": "To add a product, fill out the company profile.",
            "Товар успешно удалён.": "Product successfully deleted.",
            "Статус товара успешно изменён.": "Product status successfully changed.",
            "Продукт убран из витрины": "Product is removed from showcase.",
            "История тарифов": "Tariff history",
            "Продукт добавлен в витрину": "Product is added to showcase.",
            "В витрину": "To the showcase",
            "Уже оплачено.": "Already payed.",
            "Сделать оплату невозможно.": "Make payment impossible.",
            "Оператор": "Operator",

            "Письмо отправлено на вашу почту.": "A letter has been sent to your mail.",
            "Восстановление пароля": "Reset password",
            "Почта *": "Email *",
            "Восстановить": "Reset",
            "Вы успешно подписаны на тарифную план": "You have successfully subscribed to a tariff plan",
            "Вы уже подписаны на эту тарифную план": "You are already subscribed to this tariff plan",
            "Необходимо заполнить": "You need to fill",
            "Новый пароль успешно сохранен.": "New password saved successfully.",
            "Введите новый пароль": "Enter a new password",
            "Для восстановление пароля введите почту. Дальнейшие инструкции будут отправлены на вашу почту": "To recover the password, enter the mail. Further instructions will be sent to your mail",
            "Файл предложение": "Offer file",
            "Под заказ (дни)": "On order (days)",
            "Договорная": "Contract price",
            "Ваш отзыв успешно отправлен.": "Your review has been sent successfully.",
            "Небольшая ошибка в системе. Пожалуйста, повторите попытку позже.": "Small error in the system. Please try again later.",
            "Выбрать услугу": "Select service",
            "Детальное описание": "Detailed description",
            "Тариф не найден": "Tariff not found",
            "Оплата": "Payment",
            "О тарифных планах": "About tariffs",
            "Подписка на тариф": "Subscription to tariff",
            "Выберите метод оплаты": "Select a Payment Method",
            "Тарифный план": "Tariff plan",
            "Сравнение": "Comparison",
            "Подписка отменена.": "Subscription canceled.",
            "Просмотренные товары": "Products viewed",
            "Этот адрес электронной почты уже занят.": "This email address is already taken.",
            "Это имя пользователя уже занято.": "This username is already taken.",
            "э-почта должна быть действительной": "email must be a valid email",
            "Почта должна быть действительной": "Email must be a valid email",
            "Необходимые": "Required",
            "Регистрация прошла успешно": "Registration completed successfully",
            "Спасибо, Вы успешно зарегистрировались": "Thank you, you have successfully registered",
            "Перейти в поиск поставщиков": "Go to supplier search",
            "Проверка статуса пользователя": "Checking user status",
            "Пароль должен состоять не менее чем из 6 символов": "Password must be at least 6 characters",
            "Ваше письмо успешно отправлено.": "Your email has been sent.",
            "Username or password is invalid": "Username or password is invalid",
            "Введите вашу почту": "Enter your email",
            "Введите логин": "Enter username",
            "Введите пароль": "Enter password",
            "Введите название вашей компании": "Enter your company name",
            "Напишите сюда название товара": "Write here the name of the product",
            "Пока никто не ответил": "So far no one has answered",
            "Производители, Поставщики, Экспортеры, Изготовитель, Поставщик, Экспортер": "Manufacturers, suppliers, exporters, manufacturers, suppliers, exporters",
            "Производители, поставщики и экспортеры": "Manufacturers, Suppliers and Exporters",
            "поставщики и производители": "suppliers and manufacturers",
            "По вашему тарифу у вас нету доступа.": "You don't have access at your tariff.",
            "Возможность рекламировать свои товары": "The ability to advertise your products",
            "Email рассылка к подписчикам вашей компании": "Email newsletter to subscribers of your company",
            "Наши менеджеры заполнят ваши товары за вас": "Our managers will fill your goods for you",
            "Добавление мета-ключевых слов на страницу вашей компании": "Adding meta keywords to your company page",
            "Возможность рекламировать товары на главной странице": "Ability to advertise products on the home page",
            "Доступ к странице заявки пользователей": "Access to the user requests page",
            "Возможность загрузить логотип вашей компании": "Ability to upload your company logo",
            "Вы можете дополнительно рекламировать свои товары со скидкой": "You can further advertise your discounted items",
            "Ограничение на количество добавляемых товаров в витрину": "Limit on the number of products added to the storefront",
            "Ограничение на количество добавляемых товаров": "Limit on the number of added products",
            "Приоритет для отображения вашего продукта в списке продуктов": "Priority for displaying your product in the product list",
            "Из китая": "Chinese Equipment",
            "В наличии": "In Stock Equipment",
            "Товар успешно добавлен в сравнение. Товары в сравнение" : "Product added to comparison successfully. Products in comparison",
            "Товар успешно удален из сравнение. Товары в сравнение" : "Product removed from comparison. Products in comparision",
        }
    },
    uz: {
        translation: {
            "ВЫБОР МНОГИХ": "KO'PCHILIKNING TANLOVI",
            "Пока никто не ответил": "Hozircha hech kim javob bermadi",
            "Товар успешно добавлен в сравнение. Товары в сравнение" : "Mahsulot solishtirishga qo'shildi. Solishtirishdagi tovarlar",
            "Товар успешно удален из сравнение. Товары в сравнение" : "Mahsulot solishtirishdan o'chirildi. Solishtirishdagi tovarlar",
            "поставщики и производители": "ishlab chiqaruvchilari va yetkazib beruvchilari",
            "Производители, поставщики и экспортеры": "Ishlab chiqaruvchilar, yetkazib beruvchilar va eksport qiluvchilar",
            "Проверка статуса пользователя": "Foydalanuvchi holatini tekshirish",
            "Перейти в поиск поставщиков": "Yetkazib beruvchilarni qidirishga o'ting",
            "Спасибо, Вы успешно зарегистрировались": "Rahmat, siz muvaffaqiyatli ro'yxatdan o'tdingiz",
            "Регистрация прошла успешно": "Ro'yxatdan o'tish muvaffaqiyatli yakunlandi",
            "Необходимые": "Majburiy",
            "Найдите качественные поставщики, производители, покупатели, оптовые продавцы, продукты, экспортеры, импортеры и торговые лидеры с нашего сайта международной торговли.": "Xalqaro savdo saytimizdan sifatli yetkazib beruvchilar, ishlab chiqaruvchilar, xaridorlar, ulgurji savdogarlar, mahsulotlar, eksportchilar, importchilar va savdo liderlarini qidirib toping.",
            "Напишите сюда название товара": "Bu yerga mahsulot nomini kiriting",
            "Введите название вашей компании": "Kompaniyangiz nomini kiriting",
            "Введите пароль": "Parolni kiriting",
            "Производители, Поставщики, Экспортеры, Изготовитель, Поставщик, Экспортер": "Ishlab chiqaruvchilar, yetkazib beruvchilar, eksport qiluvchilar, ishlab chiqaruvchilar, eksport qiluvchilar",
            "Введите логин": "Loginni kiriting",
            "Бесплатно, входит в ваш тариф": "Bepul, sizning tarifingizga kiritilgan",
            "Введите вашу почту": "Pochtangizni kiritng",
            "Введите имя и фамилию": "Ism va familiyangizni kiriting",
            "э-почта должна быть действительной": "elektron pochta manzili noto'g'ri shakllantirilgan.",
            "Почта должна быть действительной": "Elektron pochta manzili noto'g'ri shakllantirilgan.",
            "Этот адрес электронной почты уже занят.": "Ushbu elektron pochta manzili allaqachon olingan.",
            "Это имя пользователя уже занято.": "Ushbu login allaqachon olingan",
            "Просмотренные товары": "Ko'rilgan mahsulotlar",
            "Подписка отменена.": "Obuna bekor qilindi.",
            "Сравнение": "Taqqoslash",
            "В наличии": "Sotuvda",
            "Тарифный план": "Tarif rejasi",
            "Показать все": "Hammasini ko'rsatish",
            "Выберите метод оплаты": "To'lov usulini tanlang",
            "Подписка на тариф": "Tarifga ulanish",
            "Оплата": "To'lov",
            "Тариф не найден": "Tarif topilmadi",
            "Вы уже подписаны на эту тарифную план": "Siz allaqachon ushbu rejaga obuna bo'lgansiz",
            "Категории": "Kategoriyalar",
            "Файл предложение": "Taklif fayli",
            "Ваше письмо успешно отправлено.": "Sizning xatingiz jo'natildi.",
            "Наличие": "Hozirda mavjud",
            "Под заказ (дни)": "Buyurtma bo'yicha (kunlar)",
            "Договорная": "Kelishilgan holda",
            "Письмо отправлено на вашу почту.": "Pochtangizga xat yuborildi.",
            "Восстановление пароля": "Parolni tiklash",
            "Почта *": "Pochta *",
            "Восстановить": "Tiklash",
            "Username or password is invalid": "Login yoki parol xato",
            "Необходимо заполнить": "To'ldirish shart",
            "Пароль должен состоять не менее чем из 6 символов": "Parol 6 belgidan kam bo'lmasligi kerak",
            "Новый пароль успешно сохранен.": "Yangi parol saqlandi.",
            "Введите новый пароль": "Yangi parolni kiriting",
            "Для восстановление пароля введите почту. Дальнейшие инструкции будут отправлены на вашу почту": "Parolni tiklash uchun pochtaningizni kiriting. Keyingi qadamlar pochtangizga yuboriladi",

            "Отправьте реквизиты вашей компании": "Rekvizitlaringizni yuboring",
            "Наименования компании": "Tashkilot nomi",
            "Ф.И.О. руководителя": "Direktor F.I.Sh.",
            "Наименования банка": "Bank nomi",
            "МФО банка": "Bank MFO",
            "Расчётный счёт": "Hisob raqam",
            "ИНН предприятия": "Tashkilot INNsi",
            "Юридический адрес предприятия": "Tashkilot yuridik nomi",

            "Оператор": "Operator",
            "Значение": "Qiymat",
            "Сделать оплату невозможно.": "To'lovni amalga oshirish mumkin emas.",
            "This username has already been taken.": "Bu login band.",
            "Чтобы добавить товар заполните профиль компании.": "Mahsulot qo'shish uchun kompaniya profilini to'ldiring",
            "Товар успешно удалён.": "Mahsulot o'chirildi.",
            "Статус товара успешно изменён.": "Mahsulot holati o'zgartirildi.",
            "Продукт убран из витрины": "Mahsulot vitrinadan olindi.",
            "Продукт добавлен в витрину": "Mahsulot vitrinaga qo'yildi.",
            "Применить": "Tasdiqlash",
            "Страна происхождения": "Ishlab chiqaruvchi mamlakat",
            "Уже оплачено.": "Allaqachon to'langan.",
            "Услуги": "Xizmatlar",
            "Для поставщиков": "Yetkazib beruvchilar uchun",
            "Для покупателей": "Sotib oluvchilar uchun",
            "Размещение рекламы": "Reklama joylash",
            "Центр поддержки": "Yordam",
            "Мой кабинет": "Kabinetim",
            "История тарифов": "Tariflar tarixi",
            "Найти": "Qidirish",
            "О тарифных планах": "Tarif rejalari haqida",
            "Найти продукт или поставщика": "Mahsulot yoki yetkazib beruvchini topish",
            "Продукт": "Mahsulot",
            "Поставщик": "Yektazib beruvchi",
            "Переписки": "Yozishma",
            "Избранные": "Tanlanganlar",
            "Заявки": "So'rovnoma",
            "ПОПУЛЯРНЫЕ КАТЕГОРИИ": "MASHHUR KATEGORIYALAR",
            "Все категории": "Hamma kategoriyalar",
            "РЕКОМЕНДУЕМЫЕ ТОВАРЫ": "TAVSIYA ETILADIGAN MAHSULOTLAR",
            "Рекомендуемые товары": "Tavsiya etiladigan mahsulotlar",
            "часа": "soat",
            "АКТИВНЫЕ КОМПАНИИ": "AKTIV KOMPANIYALAR",
            "НАШИ КЛИЕНТЫ": "BIZNING MIJOZLAR",
            "БИЗНЕС ИДЕИ": "BIZNES IDEYALAR",
            "Бизнес идеи": "Biznes ideyalar",
            "ВЫСТАВКИ": "KO'RGAZMALAR",
            "ТЕХНОЛОГИ": "TEXNOLOGLAR",
            "НОВОСТИ": "YANGILIKLAR",
            "БЫСТРЫЙ ПОИСК ОБОРУДОВАНИЙ": "MAHSULOTLARNI TEZ QIDIRISH",
            "О нас": "Biz haqimizda",
            "Обслуживание клиентов": "Mijozlarga xizmat ko'rsatish",
            "Полезная информация": "Foydali ma'lumot",
            "Наши партнеры": "Bizning hamkorlar",
            "Следите за нами": "Bizni kuzating",
            "Все правы защищены": "Hamma huquqlar ximoyalangan",
            "ООО": "MChJ",
            "ВСЕ КАТЕГОРИИ": "HAMMA KATEGORIYALAR",
            "все категории": "hamma kategoriyalar",
            "ТОВАРЫ, КОТОРЫЕ ВЫ СМОТРЕЛИ": "SIZ KO'RGAN MAHSULOTLAR",
            "товаров": "mahlusoltar",
            "Сортировка": "Saralash",
            "Название": "Nomi",
            "Дата": "Sana",
            "Просмотр": "Ko'rilganlar",
            "Популярность": "Mashhurligi",
            "Не нашли товар": "Mahsulotni topmadingizmi",
            "Оставьте заявку": "So'rov qoldiring",
            "Создать заявку": "So'rov ochish",
            "Создать ЗАЯВКУ": "So'rov ochish",
            "Написать": "Yozish",
            "Вы успешно подписаны на тарифную план": "Siz ushbu tarif rejasiga muvaffaqiyatli obuna bo'ldingiz",
            "Сравнить": "Solishtirish",
            "Страна": "Davlat",
            "Сайт": "Sayt",
            "В витрину": "Vitrinaga",
            "Фильтр товаров": "Mahsulotni filterlash",
            "Состояние": "Holati",
            "Цена": "Narx",
            "Фильтр поставщиков": "Yetkazib beruvchini filterlash",
            "Сертификаты": "Sertifikatlar",
            "Основной рынок": "Asosiy bozor",
            "Все страны": "Hamma davlatlar",
            "Тип": "Turi",
            "Очистить": "Tozalash",
            "В сравнении": "Solishtirishda",
            "Контакты компании": "Kompaniya kontaktlari",
            "О компании": "Kompaniya haqida",
            "Продукты компании": "Kompaniya mahsulotlari",
            "Профиль компании": "Kompaniya profili",
            "Модель": "Model",
            "Категория": "Kategoriya",
            "Минимальный заказ": "Eng kam buyurtma",
            "Производственная мощность": "Ishlab chiqarish quvvati",
            "Среднее время ответа": "Javob berish o'rtacha vaqti",
            "Процент ответов": "Javob berish foizi",
            "Условия и способы оплаты": "To'lov turlari",
            "Поделиться": "Baham ko'rish",
            "В избранные": "Tanlanganlarga qo'shish",
            "Удалить из избранных": "Tanlanganlardan o'chirish",
            "Скачать предложение": "Takliflarni yuklab olish",
            "Категории компании": "Kompaniya kategoriyalari",
            "Просмотреть профиль": "Profilni ko'rish",
            "Контакты": "Kontaklar",
            "Печатать страницу": "Sahifani chop etish",
            "Топ оборудования": "Top mahsulotlar",
            "Характеристики": "Xarakteristikalar",
            "Описание": "Ma'lumot",
            "Связаться с поставщиком": "Yetkazib beruvchi bilan bog'lanish",
            "Имя": "Ism",
            "Компания": "Kompaniya",
            "Телефон": "Telefon",
            "Cообщение": "Xat",
            "Почта": "Pochta",
            "Выберите": "Tanlang",
            "Рекомендовать похожих поставщиков, если этот поставщик не ответил в течении 24 часов.": "Agar 24 soat ichida javob yozmasa boshqa o'xshash yetkazib beruvchilarga ko'rsatilsin.",
            "Напишите сюда ваше сообщение": "Bu yerga xatingizni yozing",
            "Отправить": "Yuborish",
            "Часто задаваемые вопросы": "Ko'p so'raladigan savollar",
            "Отзывы клиентов": "Xaridorlar sharhlari",
            "Написать отзыв": "Sharh qoldirish",
            "Еще от этого компании": "Shu kompaniyadan boshqa mahsulotlar",
            "Подписка на товары этого поставщика": "Shu yetkazib beruvchi mahsulotlariga obuna bo'lish",
            "Подписаться": "Yozilish",
            "Введите электронную почту": "Elektron pochtangizni kiriting",
            "Ф.И.О.": "F.I.Sh.",
            "Оценка": "Baho",
            "Заголовок отзыва": "Sharh sarlavhasi",
            "Основные товары": "Asosiy mahsulotlari",
            "Все товары компании": "Kompaniya hamma mahsulotlari",
            "Найти товар": "Mahsulot qidirish",
            "Год основания": "Tashkil etilgan yil",
            "Сотрудники": "Xodimlar",
            "Тип компании": "Kompaniya turi",
            "Способы оплаты": "To'lov turlari",
            "Производитель": "Ishlab chiqaruvchi",
            "Торговый агент": "Savdo agenti",
            "Агент": "Savdo agenti",
            "Основано в": "Tashkil etilgan",
            "Все услуги": "Hamma xizmatlar",
            "Зарегистрироваться как поставщик": "Yetkazib beruvchi bo'lib ro'yxatdan o'tish",
            "Зарегистрироваться как покупатель": "Sotib oluvchi bo'lib ro'yxatdan o'tish",
            "Покупатель": "Sotib oluvchi",
            "Переписки компании": "Kompaniya yozishmalari",
            "Статистика компании": "Kompaniya statistikalari",
            "Тариф компании": "Kompaniya tarifi",
            "Заявки пользователей": "Foydalanuvchilar so'rovlari",
            "Мои заказы услуг": "Mening xizmat buyurtmalarim",
            "Мои заказы реклам": "Mening reklama buyurtmalarim",
            "Ваш персональный менеджер": "Sizning shaxsiy menejeringiz",
            "Просмотров осталось": "Ko'rsatish sonlari qoldi",
            "Рекламировать свои продукты": "Mahsulotlarimni reklama qilish",
            "Товары": "Mahsulotlar",
            "Обратная связь": "Qayta aloqa",
            "Открыть и ответить": "Ochish va javob berish",
            "Открыть": "Ochish",
            "Мои переписки": "Mening yozishmalarim",
            "Все": "Hammasi",
            "Переписка с": "Yozishma",
            "Введите сообщение": "Xatingizni yozing",
            "Вы": "Siz",
            "Город": "Shahar",
            "Адрес": "Manzil",
            "Веб-сайт": "Web-sayt",
            "Подраздел": "Ostki kategoriya",
            "Укажите категорию": "Kategoriyani ko'rsating",
            "Баннер": "Banner",
            "Добавить фото": "Rasm qo'shish",
            "Добавить сертификат": "Sertifikat qo'shish",
            "Галерея": "Galereya",
            "Логотип": "Logotip",
            "Добавить категорию": "Kategoriya qo'shish",
            "Сохранить": "Saqlash",
            "Выйти": "Chiqish",
            "Мои заявки": "Mening so'rovlarim",
            "Мои избранные": "Mening tanlaganlarim",
            "Мои подписки": "Mening obunalarim",
            "Личные данные": "Shaxsiy ma'lumotlar",
            "История": "Tarix",
            "Просмотр товаров": "Ko'rilgan mahsulotlar",
            "Сравнения": "Solishtirish",
            "Ничего не найдено": "Hech narsa topilmadi",
            "Указанная почта": "Ko'rsatilgan pochta",
            "Отменить": "Bekor qilish",
            "№ заказа": "Buyurtma №",
            "Услуга": "Xizmat",
            "Статус": "Holat",
            "Ожидание оплаты": "To'lov kutilmoqda",
            "Оплачен": "To'langan",
            "Отменён": "Bekor qilingan",
            "Удалить": "O'chirish",
            "сум": "so'm",
            "Оплатить с помощью": "Quyidagilar orqali to'lash",
            "ОПЛАТИТЬ": "TO'LASH",
            "Оплачен и выполняется": "To'langan va amalda",
            "В модерации": "Tekshirilmoqda",
            "Логин": "Login",
            "Пароль": "Parol",
            "Физическое лицо": "Jismoniy shaxs",
            "Юридическое лицо": "Yuridik shaxs",
            "Имя и фамилия": "Ism va familiya",
            "рождения": "tug'ilgan",
            "Если хотите изменить": "Agar o'zgartirmoqchi bo'lsangiz",
            "Действия": "Amal",
            "Оплатить": "To'lash",
            "Добавить продукты": "Mahsulotlar qo'shish",
            "Добавление нового товара": "Yangi mahsulot qo'shish",
            "Валюта": "Valyuta",
            "Наличие или под заказ(дни)": "Hozirgi soni yoki buyurtmaga (kun)",
            "Производительность": "Ishlab chiqaruvchanligi",
            "Добавить параметр": "Parametr qo'shish",
            "Добавить товар": "Mahsulotni qo'shish",
            "Ссылка на Ютуб": "Youtube ga havola",
            "Не загружено": "Yuklanmagan",
            "Главная картинка": "Asosiy rasm",
            "Ссылка": "Havola",
            "на Ютуб": "Youtubega",
            "Новый": "Yangi",
            "Б/У": "Ishlatilgan",
            "договорная": "kelishiladi",
            "Количество": "Soni",
            "Equip.uz - Discover your supplier": "Equip.uz - Yetkazib beruvchingizni toping",
            "просмотров": "ko'rilganlar",
            "заявок": "so'rovlar",
            "переписок": "yozishmalar",
            "Приоритет": "Muhimligi",
            "Количество товаров": "Mahsulotlar soni",
            "-й": "chi",
            " шт.": " ta",
            "Витрина товаров": "Mahsulotlar vitrinasi",
            "Скидка на дополнительную рекламу": "Qo'shimcha reklamaga chegirma",
            "Логотип компании": "Kompaniya logotipi",
            "Доступ к «Площадке заявок»": "«So'rovlar maydonchasiga» ruxsat",
            "Реклама на главной странице": "Bosh sahifada reklama",
            " показов": " ko'rsatishlar",
            " ключевых слов": " kalit so'zlar",
            "SEO оптимизация": "SEO optimallashtirish",
            "Менеджер за заполнения контента": "Kontent to'ldirishga menejer",
            "Количество<br>просмотров": "Ko'rilganlar soni",
            "Email рассылка к базе данных покупателей": "Sotib oluvchilar bazasi pochtalariga tarqatmalar",
            "Возможность рекламы": "Reklama qilish imkoniyati",
            "Уже подписаны": "Yozilgansiz",
            "Неограничено": "Cheklanmagan",
            " товаров": " mahsulotlar",
            "Добавить новый": "Yangi qo'shish",
            "Редактировать": "O'zgartirish",
            "Добавление часто задаваемого вопроса": "Ko'p so'raladigan savolni qo'shish",
            "Вопрос": "Savol",
            "Ответ": "Javob",
            "Добавить": "Qo'shish",
            "Действие выполнено успешно.": "Amal muvaffaqiyatli bajarildi.",
            "Вы уверены?": "Ishonchingiz komilmi?",
            "Подробно": "Batafsil",
            "Свернуть": "Yopish",
            "Откликов": "Qiziqishlar",
            "Результаты поиска": "Qidiruv natijalari",
            "Фильтр заявок по странам": "So'rovlarni davlatlar bo'yicha filterlash",
            "Поиск по ключевому слову": "Kalit so'z bo'yicha qidirish",
            "Новый пользователь": "Yangi foydalanuvchimisiz",
            "Логин *": "Login *",
            "Пароль *": "Parol *",
            "Запомнить меня": "Meni yodda tut",
            "Забыл пароль": "Parolni unutdim",
            "Вход": "Kirish",
            "Войти": "Kirish",
            "или": "yoki",
            "Кем вы хотите": "Kim bo'lib",
            "зарегистрироваться": "ro'yxatdan o'tmoqchisiz",
            "Покупатель товаров": "Mahsulotlar sotib oluvchi",
            "Поставщик товаров": "Mahsulotlar yetkazib beruvchi",
            "Зарегистрироваться": "Ro'yxatdan o'tish",
            "Редактирование часто задаваемого вопроса": "Ko'p so'raladigan savolni o'zgartirish",
            "Зарегистрироваться как пользователь": "Sotib oluvchi bo'lib ro'yxatdan o'tish",
            "Регистрация": "Ro'yxatdan o'tish",
            "Вы должны принять условия": "Shartlarni qabul qilishingiz kerak",
            "Что вас": "Sizni nima",
            "интересует": "qiziqtiradi",
            "Выберите подкатегорию": "Ostki kategoriyani tanlang",
            "Я согласен с": "Men roziman",
            "условиями": "shartlarga",
            "Название компании": "Kompaniya nomi",
            "Реклама": "Reklama",
            "Главная страница сайта": "Sayt bosh sahifasi",
            "Страница продуктов": "Mahsulotlar sahifasi",
            "Заказать": "Buyurtma berish",
            "Условия": "Shartlar",
            "Условия рекламы": "Reklama shartlari",
            "Заявка на рекламу": "Reklamaga so'rov",
            "Период": "Muddat",
            "Сумма": "Summa",
            "Перетащите или": "Tortib tashlang yoki",
            "загрузите": "yuklang",
            "файл баннер": "banner faylini",
            "Рекомендуемое разширение изображения": "Maslahat beriladigan rasm o'lchamlari",
            "максимальный размер 2 MB, тип файла jpeg, png.": "Eng katta hajm 2 MB, fayl tipi jpeg, png",
            "Неправильный формат": "Noto'g'ri format",
            "C": "Boshlanish",
            "по": "tugash",
            "Загружите файл": "fayl yuklang",
            "Неверный размер изображения": "Rasm o'lchamlari noto'g'ri",
            "Мои продукты": "Mening mahsulotlarim",
            "Добавить новый продукт": "Yangi mahsulot qo'shish",
            "Фильтрация": "Filterlash",
            "по рекламе": "Reklama orqali",
            "Активирован": "Aktiv",
            "Деактивирован": "Aktiv emas",
            "В ожидании": "Tekshirilmoqda",
            "Рекламировать": "Reklama qilish",
            "Дата добавления": "Qo'shilgan vaqti",
            "Дата редактирования": "O'zgartirilgan vaqti",
            "Активный": "Aktiv",
            "Деактивный": "Aktiv emas",
            "Добавить тип": "Tur qo'shish",
            "Разрешено: jpg, jpeg, png": "Ruxsat etilgan: jpg, jpeg, png",
            "Необходимо заполнить описание": "Ma'lumot to'ldirish shart",
            "Организатор": "Tashkilotchi",
            "Выставочный центр": "Ko'rgazma markazi",
            "Отправить запрос на участие данной выставки": "Ushbu ko'rgazmada qatnashish uchun so'rov yuborish",
            "Отправить запрос на данную технологу": "Ushbu texnologga so'rov yuborish",
            "Укажите хотябы один способ оплаты": "Hech bo'lmasa bitta to'lov turini tanlang",
            "Скачать типовой договор": "Odatiy shartnomani yuklab olish",
            "Январь": "Yanvar",
            "Февраль": "Fevral",
            "Март": "Mart",
            "Апрель": "Aprel",
            "Май": "May",
            "Июнь": "Iyun",
            "Июль": "Iyul",
            "Август": "Avgust",
            "Сентябрь": "Sentyabr",
            "Выставки": "Ko'rgazmalar",
            "Октрябрь": "Oktyabr",
            "Декабрь": "Dekabr",
            "Новости": "Yangiliklar",
            "Не нашли нужную выставку? Отправить запрос": "Kerakli ko'rgazmani topmadingizmi? So'rov yuborish",
            "Не нашли нужную технологу? Отправить запрос": "Kerakli texnologni topmadingizmi? So'rov yuborish",
            "Читать подробно": "Batafsil o'qish",
            "Технологи": "Texnologlar",
            "года": "yosh",
            "Выбрать услугу": "Xizmatni tanlang",
            "Наименование технологического направления": "Texnolog yo'nalishi nomi",
            "Ваш отзыв успешно отправлен.": "Sizning fikringiz muvaffaqiyatli yuborildi.",
            "Небольшая ошибка в системе. Пожалуйста, повторите попытку позже.": "Tizimda kichik xato. Iltimos keyinroq qayta urinib ko'ring.",
            "День рождения": "Tug'ilgan kuni",
            "У вас ограничение на количество товаров согласно вашему тарифу": "Tarifingiz bo'yicha sizda mahsulotlar soniga chegara mavjud",
            "Детальное описание": "Batafsil tavsif",
            "Из китая": "Xitoydan",
            "Discover your supplier": "Yetkazib beruvchingizni toping",
            "По вашему тарифу у вас нету доступа.": "Tarifingiz bo'yicha ushbu sahifaga ruxsatingiz yo'q",

            "Возможность рекламировать свои товары": "Mahsulotlaringizni reklama qilish imkoniyati",
            "Email рассылка к подписчикам вашей компании": "Kompaniyangiz obunachilariga Email tarqatish",
            "Наши менеджеры заполнят ваши товары за вас": "Bizning menejerlar mahsulotlaringizni qo'shib beradilar",
            "Добавление мета-ключевых слов на страницу вашей компании": "Kompaniya sahifangizda meta kalit so'zlar qo'shish",
            "Возможность рекламировать товары на главной странице": "Mahsulotlaringizni bosh sahifada reklama qilish",
            "Доступ к странице заявки пользователей": "Foydalanuvchilar murojaatlari sahifasiga ruxsat",
            "Возможность загрузить логотип вашей компании": "Kompaniya logotipini yuklash imkoniyati",
            "Вы можете дополнительно рекламировать свои товары со скидкой": "Qo'shimcha reklamalar uchun chegirma olish imkoniyati",
            "Ограничение на количество добавляемых товаров в витрину": "Mahsulotlaringizni vitrinaga qo'shish uchun limit",
            "Ограничение на количество добавляемых товаров": "Qo'shiladigan mahsulotlar soniga limit",
            "Приоритет для отображения вашего продукта в списке продуктов": "Mahsulotlar ro'yxatida sizning mahsulotingizni ko'rsatish prioriteti",
        }
    }
};


let language = localStorage.getItem("language");

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: language ? language : "ru",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;