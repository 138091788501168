const library: any = {
  'А': 'A',
  'а': 'a',
  'Б': 'B',
  'б': 'b',
  'В': 'V',
  'в': 'v',
  'Г': 'G',
  'г': 'g',
  'Д': 'D',
  'д': 'd',
  'Е': 'E',
  'е': 'e',
  'Ё': 'Yo',
  'ё': 'yo',
  'Ж': 'Zh',
  'ж': 'zh',
  'З': 'Z',
  'з': 'z',
  'И': 'I',
  'и': 'i',
  'Й': 'Y',
  'й': 'y',
  'К': 'K',
  'к': 'k',
  'Л': 'L',
  'л': 'l',
  'М': 'M',
  'м': 'm',
  'Н': 'N',
  'н': 'n',
  'О': 'O',
  'о': 'o',
  'П': 'P',
  'п': 'p',
  'Р': 'R',
  'р': 'r',
  'С': 'S',
  'с': 's',
  'Т': 'T',
  'т': 't',
  'У': 'U',
  'у': 'u',
  'Ф': 'F',
  'ф': 'f',
  'Х': 'Kh',
  'х': 'kh',
  'Ц': 'Ts',
  'ц': 'ts',
  'Ч': 'Ch',
  'ч': 'ch',
  'Ш': 'Sh',
  'ш': 'sh',
  'Щ': 'Sch',
  'щ': 'sch',
  'Ъ': '',
  'ъ': '',
  'Ы': 'Y',
  'ы': 'y',
  'Ь': '',
  'ь': '',
  'Э': 'E',
  'э': 'e',
  'Ю': 'Yu',
  'ю': 'yu',
  'Я': 'Ya',
  'я': 'ya',
  '’': '',
  ' ': '-',
  '?': '',
  '!': '',
  '.': '.',
  '/': '',
  '\\': '',
  '%': '',
  '&': '',
  '@': '',
  '(': '',
  ')': '',
  '_': '',
  '^': '',
  '*': '',
  '#': '',
  '№': '',
  '"': '',
  ':': '',
  ';': ''
};

export const transliterate = (str: string, numberInUrl = true) => {

  const string = str.replace(/зг/gi, 'zgh');

  const pre = string.trim();
  let result = '';
  let cnt = pre.length;

  for (let i = 0; i < cnt; i++) {
    result += library[pre[i]] || library[pre[i]] === '' ? library[pre[i]] : pre[i];
  }

  const finallyResult = numberInUrl ? result.match(/-{0,1}[A-Za-z0-9]+/gi).join('') : result.match(/-{0,1}[A-Za-z]+/gi).join('');

  return finallyResult.toLowerCase();

};

export const arrayToFormData = (data: any) => {
  let formData = new FormData;

  for (let prop in data) {

    if (Array.isArray(data[prop])) {
      for (let index in data[prop]) {
        if (!(data[prop][index] instanceof File) && typeof data[prop][index] == "object") {
          for (let p in data[prop][index]) {
            formData.append(prop + "[" + index + "][" + p + "]", data[prop][index][p]);
          }
        } else
          formData.append(prop + "[]", data[prop][index]);
      }
    } else {
      formData.append(prop, data[prop]);
    }
  }

  return formData;

};

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function number_format(number: number | string, decimals: number, dec_point: string, thousands_sep: string) {
  // Strip all characters but numerical ones.
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s: any = '',
    toFixedFix = function (n: any, prec: any) {
      var k = Math.pow(10, prec);
      return '' + Math.round(n * k) / k;
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}