let initPrevState: any = {
    isAuthentificated: false,
    user: null
};

export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_CHANGE = "USER_CHANGE";

export function auth(prevState: any = initPrevState, action: any) {
    if (action.type === USER_LOGIN) {
        return {
            isAuthentificated: true,
            user: action.user
        };
    } else if (action.type === USER_LOGOUT) {
        return initPrevState;
    } else if (action.type === USER_CHANGE) {
        return {
            isAuthentificated: prevState.isAuthentificated,
            user: {
                ...prevState.user,
                type: action.changedType
            }
        }
    }

    return prevState;
}
