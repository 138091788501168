import * as React from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { YMInitializer } from 'react-yandex-metrika';
import ym from 'react-yandex-metrika';

import GoogleTagManager from '../components/GoogleTagManager';

ReactGA.initialize('G-Q9ZQBZR52J');

interface IState {
    hasError: boolean,
    error: any,
    info: any
}

class ErrorBoundary extends React.Component<any, IState> {

    public state: IState = {
        hasError: false,
        error: null,
        info: null
    }

    componentWillReceiveProps() {
        this.setState({ hasError: false });
    }

    componentDidCatch(error: any, info: any) {
        // Display fallback UI
        this.setState({ hasError: true, error, info });
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.location !== prevProps.location) {
            window.$("html, body").animate({ scrollTop: 0 }, "fast");
            //@ts-ignore
            window.$('[data-toggle="tooltip"]').tooltip();

            window.$("div.cabinet ul.list ul.sub-list li:has(a.active)").addClass("active");

            ReactGA.pageview(window.location.pathname + window.location.search);
            ym('hit', window.location.pathname + window.location.search);
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="">
                <YMInitializer accounts={[73188616]} options={{
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true,
                    ecommerce: "dataLayer"
                }} version="2" />

                <GoogleTagManager gtmId='GTM-NNRJFJW' />

                {this.state.hasError ? (
                    <div className="i-body py-5">
                        <div className="container login-page v-index">
                            <div className="wrapper">
                                <div className="box pb-20">
                                    <h1 className="text-danger">Error page</h1>

                                    <div className="alert alert-danger">
                                        {this.state.error.message}.
                        </div>

                                    <p>
                                        {this.state.error.stack}   </p>

                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    this.props.children
                )}


            </div>
        );
    }
}

export default withRouter((ErrorBoundary));