let initPrevState: any = [];

export const FILL_SERVICE = "FILL_SERVICE";

export function service(prevState: any = initPrevState, action: any) {
    if (action.type === FILL_SERVICE) {
        
        return [
            ...action.payload
        ];
    } 
    return prevState;
}
