import React from 'react';
import ErrorBoundary from './pages/ErrorBoundary';
import { Route, Switch, BrowserRouter, Redirect, withRouter } from 'react-router-dom';

const Home = React.lazy(() => import('./pages/Home'));
const Category = React.lazy(() => import('./pages/Category'));
const Login = React.lazy(() => import('./pages/Login'));
const SignupUser = React.lazy(() => import('./pages/SignupUser'));

const NotFound = React.lazy(() => import('./pages/NotFound'));
const Confirm = React.lazy(() => import('./pages/Confirm'));
const Success = React.lazy(() => import('./pages/Success'));
const Choose = React.lazy(() => import('./pages/Choose'));
const SignupSupplier = React.lazy(() => import('./pages/SignupSupplier'));
const User = React.lazy(() => import('./pages/User'));
const Supplier = React.lazy(() => import('./pages/Supplier'));
const Product = React.lazy(() => import('./pages/Product'));
const Categories = React.lazy(() => import('./pages/Categories'));
const Tariffs = React.lazy(() => import('./pages/Tariffs'));
const CategoryProduct = React.lazy(() => import('./pages/CategoryProduct'));
const Subscribe = React.lazy(() => import('./pages/Subscribe'));
const ProductView = React.lazy(() => import('./pages/ProductView'));
const CompanyProfile = React.lazy(() => import('./pages/CompanyProfile'));
const CompanyContact = React.lazy(() => import('./pages/CompanyContact'));
const CompanyAbout = React.lazy(() => import('./pages/CompanyAbout'));
const CompanyProduct = React.lazy(() => import('./pages/CompanyProduct'));
const BusinessIdea = React.lazy(() => import('./pages/BusinessIdea'));
const News = React.lazy(() => import("./pages/News"));
const Exposition = React.lazy(() => import("./pages/Exposition"));
const ProductList = React.lazy(() => import("./pages/ProductList"));
const Technologists = React.lazy(() => import("./pages/Technologists"));
const CompanyFaq = React.lazy(() => import('./pages/CompanyFaq'));
const CompanyFaqForm = React.lazy(() => import('./pages/CompanyFaqForm'));
const Favourites = React.lazy(() => import('./pages/Favourites'));
const SupplierChat = React.lazy(() => import('./pages/SupplierChat'));
const UserChat = React.lazy(() => import('./pages/UserChat'));
const Search = React.lazy(() => import('./pages/Search'));
const SupplierRequests = React.lazy(() => import('./pages/SupplierRequests'));
const UserRequests = React.lazy(() => import('./pages/UserRequests'));
const ViewedProducts = React.lazy(() => import('./pages/ViewedProducts'));
const UserSubscribes = React.lazy(() => import('./pages/UserSubscribes'));
const UserCompare = React.lazy(() => import('./pages/UserCompare'));
const SupplierStatistics = React.lazy(() => import('./pages/SupplierStatistics'));
const Page = React.lazy(() => import('./pages/Page'));
const SearchSupplier = React.lazy(() => import('./pages/SearchSupplier'));
const Services = React.lazy(() => import('./pages/Services'));
const Service = React.lazy(() => import('./pages/Service'));
const Advertising = React.lazy(() => import('./pages/Advertising'));
const AdvertisingBuy = React.lazy(() => import('./pages/AdvertisingBuy'));
const SupplierChatView = React.lazy(() => import('./pages/SupplierChatView'));
const SupplierRequestsView = React.lazy(() => import('./pages/SupplierRequestsView'));
const UserChatView = React.lazy(() => import('./pages/UserChatView'));
const UserRequestsView = React.lazy(() => import('./pages/UserRequestsView'));
const ServicePay = React.lazy(() => import('./pages/ServicePay'));
const UserServiceOrders = React.lazy(() => import('./pages/UserServiceOrders'));
const UserAdvertisingOrders = React.lazy(() => import('./pages/UserAdvertisingOrders'));
const AdvertisingPay = React.lazy(() => import('./pages/AdvertisingPay'));
const SupplierServiceOrders = React.lazy(() => import('./pages/SupplierServiceOrders'));
const SupplierAdvertisingOrders = React.lazy(() => import('./pages/SupplierAdvertisingOrders'));
const SupplierTariffHistory = React.lazy(() => import('./pages/SupplierTariffHistory'));
const Forgot = React.lazy(() => import('./pages/Forgot'));
const ResetPassword = React.lazy(() => import('./pages/ResetPassword'));

class App extends React.Component<any, any> {
  render() {
    return (
      <ErrorBoundary>
        <React.Suspense fallback={<div className="loading-content"></div>}>
          <Switch>
            <Redirect from="/:url*(/+)" to={this.props.location.pathname.slice(0, -1)} />
            <Route path="/" exact render={(props) => <Home {...props} />} />

            <Route path="/signup-user" exact render={(props) => <SignupUser {...props} />} />
            <Route path="/signup-supplier" exact render={(props) => <SignupSupplier {...props} />} />

            <Route path="/choose" exact render={(props) => <Choose {...props} />} />
            <Route path="/confirm" exact render={(props) => <Confirm {...props} />} />
            <Route path="/success" exact render={(props) => <Success {...props} />} />

            <Route path="/login" exact render={(props) => <Login {...props} />} />
            <Route path="/forgot" exact render={(props) => <Forgot {...props} />} />
            <Route path="/reset-password/:token" exact render={(props) => <ResetPassword {...props} />} />

            <Route path="/supplier/product/update/:id" exact render={(props) => <Product {...props} />} />
            <Route path="/supplier/product/add" exact render={(props) => <Product {...props} />} />
            <Route path="/supplier/product" exact render={(props) => <ProductList {...props} />} />
            <Route path="/supplier/tariff-history" exact render={(props) => <SupplierTariffHistory {...props} />} />
            <Route path="/supplier/tariffs" exact render={(props) => <Tariffs {...props} />} />
            <Route path="/supplier/tariffs/subscribe/:id" exact render={(props) => <Subscribe {...props} />} />
            <Route path="/supplier/faq" exact render={(props) => <CompanyFaq {...props} />} />
            <Route path="/supplier/faq/update/:id" exact render={(props) => <CompanyFaqForm {...props} />} />
            <Route path="/supplier/faq/add" exact render={(props) => <CompanyFaqForm {...props} />} />
            <Route path="/supplier/chat" exact render={(props) => <SupplierChat {...props} />} />
            <Route path="/supplier/chat/:id" exact render={(props) => <SupplierChatView {...props} />} />
            <Route path="/supplier/statistics" exact render={(props) => <SupplierStatistics {...props} />} />
            <Route path="/supplier/requests" exact render={(props) => <SupplierRequests {...props} />} />
            <Route path="/supplier/requests/:id" exact render={(props) => <SupplierRequestsView {...props} />} />
            <Route path="/supplier/service-orders" exact render={(props) => <SupplierServiceOrders {...props} />} />
            <Route path="/supplier/advertising-orders" exact render={(props) => <SupplierAdvertisingOrders {...props} />} />
            <Route path="/supplier" exact render={(props) => <Supplier {...props} />} />
            <Route path="/supplier/personal" exact render={(props) => <User {...props} />} />

            <Route path="/user" exact render={(props) => <User {...props} />} />
            <Route path="/user/favorites" exact render={(props) => <Favourites {...props} />} />
            <Route path="/user/viewed-products" exact render={(props) => <ViewedProducts {...props} />} />
            <Route path="/user/subscribes" exact render={(props) => <UserSubscribes {...props} />} />
            <Route path="/user/service-orders" exact render={(props) => <UserServiceOrders {...props} />} />
            <Route path="/user/advertising-orders" exact render={(props) => <UserAdvertisingOrders {...props} />} />
            <Route path="/user/chat" exact render={(props) => <UserChat {...props} />} />
            <Route path="/user/chat/:id" exact render={(props) => <UserChatView {...props} />} />
            <Route path="/user/compare" exact render={(props) => <UserCompare {...props} />} />
            <Route path="/user/requests" exact render={(props) => <UserRequests {...props} />} />
            <Route path="/user/requests/:id" exact render={(props) => <UserRequestsView {...props} />} />

            <Route path="/categories" exact render={(props) => <Categories {...props} />} />

            <Route path="/product-view/:id" exact render={(props) => <ProductView {...props} />} />
            <Route path="/page/:url" exact render={(props) => <Page {...props} />} />

            <Route path="/advertising" exact render={(props) => <Advertising {...props} />} />
            <Route path="/advertising/:product_id" exact render={(props) => <Advertising {...props} />} />
            <Route path="/advertising-buy/:id/:product_id" exact render={(props) => <AdvertisingBuy {...props} />} />
            <Route path="/advertising-buy/:id" exact render={(props) => <AdvertisingBuy {...props} />} />
            <Route path="/advertising-pay/:id" exact render={(props) => <AdvertisingPay {...props} />} />

            <Route path="/company-profile/:id" exact render={(props) => <CompanyProfile {...props} />} />
            <Route path="/company-profile/:id/:page" exact render={(props) => <CompanyProfile {...props} />} />
            <Route path="/company-contact/:id" exact render={(props) => <CompanyContact {...props} />} />
            <Route path="/company-about/:id" exact render={(props) => <CompanyAbout {...props} />} />
            <Route path="/company-product/:id" exact render={(props) => <CompanyProduct {...props} />} />
            <Route path="/company-product/:id/:page" exact render={(props) => <CompanyProduct {...props} />} />

            <Route path="/services" exact render={(props) => <Services {...props} />} />
            <Route path="/service/:id" render={(props) => <Service {...props} />} />
            <Route path="/service-pay/:id" render={(props) => <ServicePay {...props} />} />

            <Route path="/business-idea/" exact render={(props) => <BusinessIdea {...props} />} />
            <Route path="/business-idea/:page" exact render={(props) => <BusinessIdea {...props} />} />
            <Route path="/business-idea-view/:id" exact render={(props) => <BusinessIdea {...props} />} />
            <Route path="/news" exact render={(props) => <News {...props} />} />
            <Route path="/news-view/:id" exact render={(props) => <News {...props} />} />
            <Route path="/exposition" exact render={(props) => <Exposition {...props} />} />
            <Route path="/exposition-view/:id" exact render={(props) => <Exposition {...props} />} />
            <Route path="/technologists" exact render={(props) => <Technologists {...props} />} />
            <Route path="/technologist-view/:id" exact render={(props) => <Technologists {...props} />} />

            <Route path="/search/supplier" exact render={(props) => <SearchSupplier {...props} />} />
            <Route path="/search/supplier/:value" exact render={(props) => <SearchSupplier {...props} />} />
            <Route path="/search/product" exact render={(props) => <Search {...props} />} />
            <Route path="/search/product/:value" exact render={(props) => <Search {...props} />} />
            <Route path="/search/product/:value/:category_id" exact render={(props) => <Search {...props} />} />
            <Route path="/from-china" exact render={(props) => <Search {...props} />} />
            <Route path="/in-stock" exact render={(props) => <Search {...props} />} />

            <Route path="/not-found" exact render={(props) => <NotFound {...props} />} />
            <Route path="/:parent_url" exact render={(props) => <Category {...props} />} />
            <Route path="/:parent_url/:page(\d+)" exact render={(props) => <Category {...props} />} />
            <Route path="/:parent_url/:url" exact render={(props) => <CategoryProduct {...props} />} />
            <Route path="/:parent_url/:url/:page(\d+)" exact render={(props) => <CategoryProduct {...props} />} />
            <NotFound />
          </Switch>
        </React.Suspense>
      </ErrorBoundary>
    );
  }
}

export default withRouter(App);